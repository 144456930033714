@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100;9..40,200;9..40,300;9..40,400;9..40,500;9..40,600;9..40,700;9..40,800;9..40,900;9..40,1000&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html, 
  body,
  #root {
    @apply min-h-screen bg-[var(--background)] font-['DM_Sans'];
    font-family: 'Inter', sans-serif;
    --primary: #FF9F6A;
    --primary-hover: #FF8A4D;
    --background: #FDF6F4;
    --primary-text: #FF8A4D;
    --secondary-text: #FFFFFF;
    --secondary:#FFFFFF
  }
  
  :root {
    --background: #FDF6F4;
    --foreground: 222.2 47.4% 11.2%;
 
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;
 
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
 
    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;
 
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
 
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
 
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
 
    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --success: 142.1 76.2% 36.3%;
    --success-foreground: 355.7 100% 97.3%;

    --danger: 0 84.2% 60.2%;
    --danger-foreground: 210 40% 98%;
 
    --ring: 215 20.2% 65.1%;
 
    --radius: 0.5rem;

    --book-shloka-bg: 24 30% 97%;
    --book-shloka-text: 24 30% 23%;
    --book-shloka-border: 24 35% 85%;
    
    --book-translation-bg: 24 35% 97%;
    --book-translation-border: 24 35% 85%;
    --book-translation-text: 24 30% 23%;
    
    --book-explanation-bg: 24 40% 97%;
    --book-explanation-border: 24 35% 85%;
    --book-explanation-text: 24 30% 23%;
    
    --book-qna-bg: 24 45% 97%;
    --book-qna-border: 24 35% 85%;
    --book-qna-text: 24 30% 23%;

    --book-topic-bg: 24 50% 97%;
    --book-topic-border: 24 35% 85%;
    --book-topic-text: 24 30% 23%;

    --book-description-bg: 24 55% 97%;
    --book-description-text: 200 30% 23%;

    --book-explanation-heading: 222.2 47.4% 11.2%;
    --book-explanation-text: 215 20.2% 30.1%;

   
    --tab-background: #e4c1be;
    --tab-text: #FFFFFF;
    --tab-hover: #FFFFFF;
    --tab-active-background: #FF9F6A;
    --tab-active-text: #FFFFFF;

    /* Skeleton Loading Colors */
    --skeleton-title: 24 30% 92%;
    --skeleton-content: 24 25% 94%;
    --skeleton-highlight: 24 20% 98%;
  }
 
  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;
 
    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;
 
    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;
 
    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;
 
    --border: 216 34% 17%;
    --input: 216 34% 17%;
 
    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;
 
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;
 
    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;
 
    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --success: 142.1 70.2% 29.2%;
    --success-foreground: 144.9 80.4% 10%;

    --danger: 0 70% 50.2%;
    --danger-foreground: 210 40% 98%;
 
    --ring: 216 34% 17%;
 
    --radius: 0.5rem;

    --book-shloka-bg: 224 30% 8%;
    --book-shloka-text: 224 20% 90%;
    --book-shloka-border: 224 25% 25%;
    
    --book-translation-bg: 224 35% 8%;
    --book-translation-border: 224 25% 25%;
    --book-translation-text: 224 20% 90%;
    
    --book-explanation-bg: 224 40% 8%;
    --book-explanation-border: 224 25% 25%;
    --book-explanation-text: 224 20% 90%;
    
    --book-qna-bg: 224 45% 8%;
    --book-qna-border: 224 25% 25%;
    --book-qna-text: 224 20% 90%;

    --book-topic-bg: 224 50% 8%;
    --book-topic-border: 224 25% 25%;
    --book-topic-text: 224 20% 90%;

    --book-description-bg: 224 55% 8%;
    --book-description-text: 224 20% 90%;

    --book-explanation-heading: 224 20% 90%;
    --book-explanation-text: 224 20% 80%;

    --tab-background: #B42318;
    --tab-text: #B3B8C2;
    --tab-hover: #3B4148;
    --tab-active-background: #2F343A;
    --tab-active-text: #FFFFFF;

    /* Skeleton Loading Colors */
    --skeleton-title: 224 30% 15%;
    --skeleton-content: 224 25% 12%;
    --skeleton-highlight: 224 20% 20%;
  }
}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer components {
  .shloka {
    @apply font-serif text-[1em] bg-[hsl(var(--book-shloka-bg))] text-[hsl(var(--book-shloka-text))] 
           p-3 rounded border-l-4 border-[hsl(var(--book-shloka-border))] leading-relaxed my-4 whitespace-pre-line;
  }

  .translation {
    @apply my-3 p-3 bg-[hsl(var(--book-translation-bg))] text-[hsl(var(--book-translation-text))]
           border-l-4 border-[hsl(var(--book-translation-border))] rounded-sm;
  }

  .explanation {
    @apply my-3 p-3 bg-[hsl(var(--book-explanation-bg))] text-[hsl(var(--book-explanation-text))]
           border-l-4 border-[hsl(var(--book-explanation-border))] rounded-sm;
  }

  .qna {
    @apply my-3 p-3 bg-[hsl(var(--book-qna-bg))] text-[hsl(var(--book-qna-text))]
           border-l-4 border-[hsl(var(--book-qna-border))] rounded-sm;
  }

  .book-topic {
    @apply my-3 p-3 bg-[hsl(var(--book-topic-bg))] text-[hsl(var(--book-topic-text))]
           border-l-4 border-[hsl(var(--book-topic-border))] rounded-sm;
  }

  .book-description {
    @apply my-3 p-3 bg-[hsl(var(--book-description-bg))] text-[hsl(var(--book-description-text))]
           border-l-4 border-[hsl(var(--book-description-border))] rounded-sm;
  }
}
